import React, { Fragment } from "react"
import Img from "gatsby-image"
import { UseQueryData } from "../hooks/use-query"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { AboutWrapper } from "../components/Style/about"

const AboutPage = () => {
  const data = UseQueryData()
  const filteredData = data.edges.filter(object => object.node.fields.sourceName === "other")
  const theBackground = filteredData[0].node.frontmatter.featuredImage.childImageSharp.fluid
  const theHTML = filteredData[0].node.html

  return (
    <Fragment>
      <Seo
        title={"About"}
        description={"Perth based UX developer who loves to work with React.js and GraphQL. Creator of Flexi Tennis Leagues AU and Ninja of life."}
      />
      <Layout type={"about"}>
        <AboutWrapper>
          <div className="content">
            <div className="col-1">
              <h1>Who am I?</h1>
              <div dangerouslySetInnerHTML={{ __html: theHTML }} />
            </div>
            <div className="col-2">
              <div id="profile-image">
                <Img fluid={theBackground} alt="developer profile image" />
              </div>
            </div>
          </div>
        </AboutWrapper>
      </Layout>
    </Fragment>
  )
}

export default AboutPage
