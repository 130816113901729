import styled from "styled-components"

export const AboutWrapper = styled.main`
  #profile-image {
    margin: 0 auto;
    max-width: 310px;
  }

  @media (max-width: 1115px) {
    .content {
      align-items: unset;
      flex-direction: column;
      padding: 0 40px;

      .col-1 {
        margin-bottom: 40px;
      }
    }
  }
`
